import React, { useState, useLayoutEffect } from "react"
import { Link } from "gatsby"

const AboutNav = props => {
  const [search, setSearch] = useState(() => {
    if (typeof window !== "undefined") return window.location.search
    return ""
  })
  const [url, setPageUrl] = useState(() => {
    if (typeof window !== "undefined") return window.location.href
    return ""
  })

  const [loaded, setLoaded] = useState(false)

  useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      setSearch(window.location.search)
      setPageUrl(window.location.href)
      setLoaded(true)
    }
  })

  return (
    <div className="about_nav_main">
      {loaded && (
        <Link
          to={`/events/${props.slug}/about${search}`}
          name="about"
          className={url.includes("about") ? "active" : ""}
        >
          About
        </Link>
      )}
      {loaded && (
        <Link
          to={`/events/${props.slug}/agenda${search}`}
          name="agenda"
          className={url.includes("agenda") ? "active" : ""}
        >
          Agenda
        </Link>
      )}
      {loaded && (
        <Link
          to={`/events/${props.slug}/speakers${search}`}
          name="speakers"
          className={url.includes("speakers") ? "active" : ""}
        >
          Speakers
        </Link>
      )}
      {loaded && (
        <Link
          to={`/events/${props.slug}/sponsors${search}`}
          name="sponsors"
          className={url.includes("sponsors") ? "active" : ""}
        >
          Sponsors
        </Link>
      )}
      {/* {loaded && (
        <Link
          to={`/events/${props.slug}/activities${search}`}
          name="activities"
          className={url.includes("activities") ? "active" : ""}
        >
          Activities
        </Link>
      )} */}
      {loaded && (
        <Link
          to={`/events/${props.slug}/faq${search}`}
          name="faq"
          className={url.includes("faq") ? "active" : ""}
        >
          FAQ
        </Link>
      )}
      {/* {loaded && (
        <Link
          to={`/events/${props.slug}/register${search}`}
          name="register"
          className={url.includes("register") ? "active" : ""}
        >
          Register
        </Link>
      )} */}
    </div>
  )
}
export default AboutNav

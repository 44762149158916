import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
const Banner = props => {
  const [windowWidth, setWindowWidth] = useState(() => {
    if (typeof window !== "undefined") return window.innerWidth
  })
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth)
    })
    return () => {
      window.removeEventListener("resize", () => {})
    }
  }, [])

  return (
    <div
      className={
        props.transparentHeader || props.bannerLogo
          ? "banner_main1 banner_main_london"
          : "banner_main1"
      }
    >
      <div
        className={
          props.transparentHeader || props.bannerLogo
            ? "banner_ct_data banner_london_m_img"
            : "banner_ct_data"
        }
      >
        {props?.bannerLogo ? (
          <img src={props.bannerLogo.file.url} alt="banner"></img>
        ) : (
          <h1 className="banner_title">{props.title}</h1>
        )}
        {props?.bannerLogo ? (
          <></>
        ) : (
          <p className="banner_date">{props.date}</p>
        )}
        {/* <div className="banner_btn">
          {(props?.registrationUrl || props?.watchOnDemandUrl) && (
            <Link
              href={
                props?.headerbtn === "WATCH ON DEMAND"
                  ? props?.watchOnDemandUrl
                  : props?.registrationUrl
              }
            >
              {props?.headerbtn}
            </Link>
          )}
        </div> */}
      </div>
    </div>
  )
}
export default Banner

import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

const Header = props => {
  const data = useStaticQuery(graphql`
    query {
      contentfulHomePage {
        kafkaSummitLogo {
          file {
            url
          }
        }
        kafkaSummitWhiteLogo {
          file {
            url
          }
        }
      }
    }
  `)

  return (
    <div
      className={
        props?.transparentHeader
          ? "header_wrap transparent_header"
          : "header_wrap"
      }
      style={{ backgroundColor: props?.bgColor === "black" ? "#000000" : "" }}
    >
      <div className="header_logo">
        <Link to={`/`}>
          <img
            src={
              props?.color === "white"
                ? data?.contentfulHomePage?.kafkaSummitWhiteLogo?.file?.url
                : data?.contentfulHomePage?.kafkaSummitLogo?.file?.url
            }
            alt="logo"
          />
        </Link>
      </div>
      <div className="header_btn">
        {props?.headerbtn === "WATCH ON DEMAND" && props?.watchOnDemandUrl && (
          <a
            target="_blank"
            rel="noreferrer"
            href={props?.watchOnDemandUrl}
            className="register"
            // style={{
            //   backgroundColor:
            //     props?.buttonBgColor === "green" ? "#007579" : "#ffffff",
            //   color: props?.color === "white" ? "#000000" : "ffffff",
            // }}
          >
            {props?.headerbtn}
          </a>
        )}
        {(props?.headerbtn === "Sign Up For Updates" ||
          props?.headerbtn === "ENTER SUMMIT") &&
          props?.signUpUrl && (
            <Link
              to={props?.signUpUrl}
              className="register"
              // style={{
              //   backgroundColor:
              //     props?.buttonBgColor === "white" ? "#ffffff" : "#007579",
              //   color: props?.buttonBgColor === "green" ? "#ffffff" : "#000000",
              // }}
            >
              Sign Up For Updates
            </Link>
          )}

        {(props?.headerbtn === "WATCH LIVESTREAM" ||
          props?.headerbtn === "SIGN UP FOR UPDATES" ||
          props?.headerbtn === "Register Today") &&
          props?.registrationUrl && (
            <Link
              to={props?.registrationUrl}
              style={{
                backgroundColor:
                  props?.buttonBgColor === "white" ? "#ffffff" : "#007579",
                color: props?.buttonBgColor === "green" ? "#ffffff" : "#000000",
              }}
            >
              {props?.headerbtn}
            </Link>
          )}
      </div>
    </div>
  )
}

export default Header
